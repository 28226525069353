import { PageProps } from '@lp-root/src/utils/local-types';
import { Grid } from '@web/atomic';
import { graphql } from 'gatsby';
import * as React from 'react';
import CleanLayout from '../components/org.layout/clean-layout.component';
import MultistepForm from '../modules/quiz-b2b/multistep-quiz.component';
import { appPaths } from '../utils/path';
import { CustomQuery } from './quiz-b2b';

const StartForm2: React.FunctionComponent<PageProps<unknown, CustomQuery>> = (props) => {
  return (
    <>
      <CleanLayout>
        <Grid>
          <MultistepForm {...props} resultsPath={appPaths.b2bResults.path} />
        </Grid>
      </CleanLayout>
    </>
  );
};

export const query = graphql`
  query StartQueryForm2 {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default StartForm2;
